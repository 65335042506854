import { Route, Switch } from 'react-router-dom';
import LandingPage from './landing/LandingPage';
import CommonHeader from './CommonHeader';
import InnerHeader from './InnerHeader';
import BusinessSelectionPage from './businessSelection/BusinessSelectionPage';
import HistoryPage from './history/HistoryPage';
import HelpPage from './help/HelpPage';
import DrillDownPage from './drilldown/DrillDownPage';
import React from 'react';
import QuicksightProspero from './quicksiteEmbed/QuicksiteEmbedProspero';
import QuicksightBosco from './quicksiteEmbed/QuicksiteEmbedBosco';

export default function App() {
  return (
    <div>

      <Switch>
        <Route exact path="/">

          <InnerHeader />
          <BusinessSelectionPage></BusinessSelectionPage>
        </Route>

        <Route path="/drilldown/:drilldown/:id">
          <InnerHeader />
          <DrillDownPage></DrillDownPage>
        </Route>

        <Route exact path="/landing" >
          <InnerHeader />
          <LandingPage></LandingPage>
        </Route>

        <Route exact path="/help" >
          <InnerHeader />
          <HelpPage></HelpPage>
        </Route>

        <Route exact path="/history" >
          <InnerHeader />
          <HistoryPage></HistoryPage>
        </Route>
        
        <Route exact path="/quicksightProspero" >
          <InnerHeader />
          <QuicksightProspero></QuicksightProspero>
        </Route>

        <Route exact path="/quicksightBosco" >
          <InnerHeader />
          <QuicksightBosco></QuicksightBosco>
        </Route>
      </Switch>
    </div>
  );
}
