import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';

// The content in the main content area of the App layout
export default function HelpPage() {
  return (
    <>
      <Grid
        gridDefinition={[
          { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
        ]}
      >
        <SpaceBetween size="l">
          <div>
            <Container>
              <label>Hi, this is the Help Page</label>
              <a href='https://w.amazon.com/bin/view/F3/F3_Grocery_Supply_Chain/ASIN_Forecasting/Engineering/'>Find our Engineer!</a>
            </Container>
          </div>
        </SpaceBetween>
      </Grid>
    </>
  );
}
